.instagram_feed {
  display: block;
  margin: 41px auto 0;
}
@media screen and (min-width: 768px) {
  .instagram_feed {
    max-width: 1040px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 767px) {
  .instagram_feed {
    max-width: calc(100% - 40px);
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 767px) {
  .instagram_feed {
    margin-top: 37px;
  }
}
.instagram_feed .eapps-widget-toolbar,
.instagram_feed .eapps-instagram-feed-posts-grid-load-more-container.eapps-instagram-feed-posts-grid-load-more-enabled {
  display: none !important;
}