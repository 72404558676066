@charset "UTF-8";


// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;


// ==================================================
//  Instagram
// ==================================================
.instagram {
  &_feed {
    display: block;
    @include mixin.contentWidth;
    margin: 41px auto 0;

    @include mixin.sp {
      margin-top: 37px;
    }

    // Elfsight
    .eapps-widget-toolbar,
    .eapps-instagram-feed-posts-grid-load-more-container.eapps-instagram-feed-posts-grid-load-more-enabled {
      display: none !important;
    }
  }
}